import { Maybe } from '@/api/graphql/schema/app.graphql.types'
import { RngKey8 } from '@/common/utils/PRNGFunctions'

export enum PRICEITEM_NAME {
  brick = 'Cihla',
  ext = 'EXT',
  frame = 'Zalitá zárubeň',
  additionalWorks = 'Vícepráce',
  discount = 'Sleva',
  application_none = 'Nechci páskový dekor',
  application_inner = 'Vnitřní',
  application_outer = 'Vnější',
  application_both = 'Obě',
  doorPlate_name = 'Chci jmenovku',
  doorPlate_name_none = 'Nechci jmenovku',
  doorPlate_number = 'Chci číslo',
  doorPlate_number_none = 'Nechci číslo'
}

export enum PRICEITEM_KEY {
  totalprice = 'totalPrice',
  baseprice = 'baseprice',
  shipping = 'shipping',
  additional = 'additional',
  brick = 'zdivo_cihla',
  frame = 'zdivo_zalitazaruben',
  discount = 'sleva',
  application = 'application',
  doorplate_name = 'doorplate_name',
  doorplate_number = 'doorplate_number',
  doorplate_name_number = 'doorplate_name_number'
}

/** */
export type PriceItem = {
  /** object key for price summary line - ak tam je nejake ID tak len pre ucely appky */
  key: PRICEITEM_KEY.baseprice | PRICEITEM_KEY.shipping | PRICEITEM_KEY.additional | PRICEITEM_KEY.brick | PRICEITEM_KEY.frame
  | PRICEITEM_KEY.application | PRICEITEM_KEY.doorplate_name | PRICEITEM_KEY.doorplate_number  | PRICEITEM_KEY.doorplate_name_number | string
  /** nazov riadku ceny */
  serviceName: string
  /** je tento pricerow defaultny (nevygenerovany uzivatelom cez pridanie viceprace) */
  isDefault: boolean

  originalPrice?: string

  /** cena s dph */
  price: string

  /** je cena editovatelna? */
  priceEdit: boolean

  /** editovatelny field uzivatelom */
  desc: string
  /** povolenie pre zobrazenie fieldu */
  allowDesc: boolean

  /** allow description edit for non default item, for example discount  */
  editDesc: boolean

  /** required field */
  required: boolean
}

/**
 *
 * @param key unique ID for this price item
 * @param allowDesc allow description text field (which appears to the right to the price text field)
 * @param priceEdit is the price editable?
 * @param serviceName text to fill the description label in price text field
 * @param desc description of the bonus optional field (right next to the price textfield. has no effect if "allowDesc" is false)
 * @param price main data - the price, but as a string, not number
 * @param originalPrice
 * @returns PriceItem object
 */
export const PriceItem = (key: string, allowDesc: boolean, priceEdit: boolean, serviceName?: string, desc?: string, price?: string, originalPrice?: string, editDesc?: boolean, required?: boolean):
PriceItem => {
  console.log(serviceName)
  return {
    allowDesc,
    priceEdit,
    key: key === '' ? RngKey8() : key,
    isDefault: key !== '',
    price: price || '0',
    originalPrice: originalPrice || '0',
    serviceName: serviceName || PRICEITEM_NAME.additionalWorks,
    desc: desc || '',
    editDesc: editDesc || false,
    required: required || false,
  }
}

export const PriceItemImport = (s?: Maybe<string>): PriceItem[] => s ? JSON.parse(s) : []
